import React from 'react'
import {AttachMoney, Build, Home, LibraryBooks, Store} from '@mui/icons-material'
import i18n from '../i18n'
import {findI18nTermFromEn} from '../util/SlugHelper'

const language = i18n?.language?.split('-')[0] || 'en'

const articlesSegment = findI18nTermFromEn('articles', language)
const financialGlossarySegment = findI18nTermFromEn('financial-glossary', language)

const homeLink = `/${language}/`
const articlesLink = `/${language}/${articlesSegment}`
const loanLink = `/${language}/${financialGlossarySegment}/${findI18nTermFromEn('loan', language)}`
const mortgageLink = `/${language}/${financialGlossarySegment}/${findI18nTermFromEn('mortgage', language)}`
const financialGlossaryLink = `/${language}/${financialGlossarySegment}`
const toolsLink = `/${language}/${findI18nTermFromEn('tools', language)}`

export const MENU_ITEMS = [
    {label: 'home', icon: <Home fontSize='medium' sx={{color: 'wheat'}}/>, link: homeLink},
    {label: 'articles', icon: <LibraryBooks fontSize='medium' sx={{color: 'wheat'}}/>, link: articlesLink},
    {label: 'loan', icon: <AttachMoney fontSize='medium' sx={{color: 'wheat'}}/>, link: loanLink},
    {label: 'mortgage', icon: <Store fontSize='medium' sx={{color: 'wheat'}}/>, link: mortgageLink},
    {label: 'glossary', icon: <LibraryBooks fontSize='medium' sx={{color: 'wheat'}}/>, link: financialGlossaryLink},
    {label: 'tools', icon: <Build fontSize='medium' sx={{color: 'wheat'}}/>, link: toolsLink}
]
