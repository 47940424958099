import React, {lazy, useState} from 'react'
import './App.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Container, CssBaseline} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ResponsiveNavBar from './component/ResponsiveNavBar/ResponsiveNavBar'
import Sidebar from './component/Sidebar/Sidebar'
import SuspenseLoading from './component/Suspense/SuspenseLoading'

export default function App() {

    const Home = lazy(() => import('./page/Home/Home'))
    const MainLanguageSlugPage = lazy(() => import('./page/MainLanguageSlugPage/MainLanguageSlugPage'))

    const customTheme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                mi: 769,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        }
    })

    const [isOpen, setIsOpen] = useState(true)

    return (
        <ThemeProvider theme={customTheme}>
            <Router>
                <CssBaseline/>
                <Container
                    id={'App'} disableGutters={true}
                    maxWidth={false}
                    style={{height: '100vh', minHeight: '100vh'}}
                >
                    <ResponsiveNavBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                    <Grid container columns={14} spacing={0.1}>
                        <Grid item size={{xs: 0, md: isOpen ? 2 : 0}} style={{
                            height: '100vh',
                            minHeight: '100vh',
                            position: 'fixed'
                        }}>
                            <Sidebar isOpen={isOpen}/>
                        </Grid>
                        <Grid item id={'Main'} size='grow' offset={{xs: 0, md: isOpen ? 2 : 0}} style={{}}>
                            <Routes>
                                <Route path='/:lang/'
                                       element={<SuspenseLoading component={<Home/>}/>}
                                />
                                <Route path='/:lang/:main'
                                       element={<SuspenseLoading component={<MainLanguageSlugPage/>}/>}
                                />
                                <Route path='/:lang/:main/:first'
                                       element={<SuspenseLoading component={<MainLanguageSlugPage/>}/>}
                                />
                                <Route path='/:lang/:main/:first/:second'
                                       element={<SuspenseLoading component={<MainLanguageSlugPage/>}/>}
                                />
                                <Route path='/:lang/:main/:first/:second/:third'
                                       element={<SuspenseLoading component={<MainLanguageSlugPage/>}/>}
                                />
                            </Routes>
                        </Grid>
                    </Grid>
                </Container>
            </Router>
        </ThemeProvider>
    )
}
