import React from 'react'
import './ResponsiveNavBar.css'
import {AppBar, useMediaQuery, useTheme} from '@mui/material'
import Header from '../Header/Header'


export default function ResponsiveNavBar({isOpen, setIsOpen}) {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <AppBar id={'ResponsiveNavBar'} position='fixed'>
            <Header isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile}/>
        </AppBar>
    )
}
