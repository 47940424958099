import React, {Suspense} from 'react'
import './SuspenseLoading.css'
import Grid from '@mui/material/Grid2'
import {CircularProgress} from "@mui/material";
import {Trans} from "react-i18next";


export default function SuspenseLoading({component, fallback = true}) {

    return (
        <Suspense fallback={
            <>
                {fallback &&
                    <Grid container className={'Loading'} columns={3}>
                        <Grid item>
                            <Trans i18nKey={'loading'}/><CircularProgress/>
                        </Grid>
                    </Grid>
                }
            </>
        }>
            {component}
        </Suspense>
    )
}